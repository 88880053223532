import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor, history } from './store';
import * as serviceWorker from './serviceWorker';
import {
  PublicRoute,
  Login,
  Verify
} from 'containers';

import './index.css';
import './admin.css';

import * as Sentry from '@sentry/browser';
import { SENTRY_DSN } from 'configs';

Sentry.init({dsn: SENTRY_DSN});

require('@fortawesome/fontawesome-pro/css/solid.css');
require('@fortawesome/fontawesome-pro/css/regular.css');
require('@fortawesome/fontawesome-pro/css/light.css');
require('@fortawesome/fontawesome-pro/css/brands.css');
require('@fortawesome/fontawesome-pro/css/fontawesome.css');

console.info(`Grata Data Version ${(process.env.VERSION || '').replace(/"/g, '') || '{ERROR}'}`);

ReactDOM.render((
  <Provider store={store} key="provider">
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/login" to="/"/>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute exact path="/password/reset/" component={Login} />
          <PublicRoute path="/activate/" component={Verify} />
          <Redirect from="*" status={404} to="/" />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


