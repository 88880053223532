import { get, post, delay } from 'utils';

const LOGIN = 'auth/LOGIN_SUCCESS';
const LOGOUT = 'auth/LOGOUT';

const initialState = {
  user: {},
};

export default function reducer(state = initialState, action = {}) {

  switch (action.type) {

    case LOGIN:
      return {
        ...state,
        user: {
          ...action.payload.user
        }
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
}

export const login = (email, password) => {
  return post({
    endpoint: '/authenticate/',
    body: { email, password },
    types: [0, LOGIN, 0]
  })
}

export const logout = () => delay({ type: LOGOUT })

export const forgotPassword = email => {
  return post({
    endpoint: '/password/forgot/',
    body: { email },
    types: [0, 0, 0]
  })
}

export const loadResetToken = token => {
  return get({
    endpoint: `/password/reset/?token=${token}`,
    types: [0, 0, 0]
  })
}

export const resetPassword = accountData => {
  return post({
    endpoint: `/password/reset/`,
    body: accountData,
    types: [0, 0, 0]
  })
}

export const verify = token => {
  return get({
    endpoint: `/activate/?key=${token}`,
    types: [0,0,0]
  })
}