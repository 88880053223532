import storage from 'redux-persist/es/storage';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import authorizationMiddleWare from './reducers/middleware/authorizationMiddleWare';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers';

export const history = createBrowserHistory();

const reducer = persistReducer({
    key: 'root',
    storage: storage,
    whitelist: ['auth'],
    transforms: []
}, createRootReducer(history));

const configureStore = preloadedState => {
    return createStore(
        reducer,
        preloadedState,
        compose(
            applyMiddleware(
                thunk,
                apiMiddleware,
                routerMiddleware(history),
                authorizationMiddleWare,
            )
        )
    );
}

export const store = configureStore({});
export const persistor = persistStore(store);
