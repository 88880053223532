import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  SEARCH_COOKIE_DOMAIN, 
  SEARCH_DOMAIN, 
  DATA_COOKIE_DOMAIN,
  DATA_DOMAIN 
} from 'configs';
import { 
  login, logout,
  forgotPassword, loadResetToken,
  resetPassword
} from 'reducers/auth';
import { isEnter, parseQuery } from 'utils';
import { ReactComponent as Logo } from 'static/logo.svg';

require('./Login.css');

class Login extends Component {
  state = {
    error: null,
    loading: false,
    forgot: false,
    emailSent: false,
    reset: false,
    redirect: false,

    resetInfo: {}
  }

  meta = {}

  componentWillMount = () => {
    const hash = (this.props.location.hash || '').replace('#','');
    if(hash === 'forgot') {
      this.setState({ forgot: true });
    }
    const params = parseQuery(this.props.location.search);
    if(params.token) {
      this.props.loadResetToken(params.token).then(res => {
        if (res.payload.valid === false || res.error) {
          this.setState({error: res.payload.response.errors.join(' ')})
        } else {
          this.setState({
            resetInfo: { 
              token: params.token, 
              email: res.payload.user_email,
              name: res.payload.user_first_name
            }
          })
        }
      });
    }
    if(this.props.authenticated) this.props.logout();
    if(this.props.location.pathname.includes('reset')) this.setState({ reset: true });
  }

  componentDidUpdate = () => {
    if(this.props.location.pathname.includes('reset')) {
      if(!this.state.reset) this.setState({ reset: true });
    } 
    else if(this.state.reset) this.setState({ reset: false });
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.keyPressListener);
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.keyPressListener);
    this._isMounted = false;
  }

  redirectToSearch = () => {
    const { user } = this.meta;
    // set cookie with authentication
    const cookie = `grata-token= ${user.token}; Secure;${SEARCH_COOKIE_DOMAIN ? ` domain= ${SEARCH_COOKIE_DOMAIN};` : ''} path=/`;
    document.cookie = cookie;
    // redirect to search
    window.location.href = SEARCH_DOMAIN;
  }

  redirectToData = () => {
    const { user } = this.meta;
    // set cookie with authentication
    const cookie = `grata-token= ${user.token}; Secure;${DATA_COOKIE_DOMAIN ? ` domain= ${DATA_COOKIE_DOMAIN};` : ''} path=/`;
    document.cookie = cookie;
    // redirect to data
    console.log(cookie)
    console.log('logging in to', DATA_DOMAIN)
    window.location.href = DATA_DOMAIN;
  }

  submit = () => {
    if(this.state.forgot) return this.sendForgotEmail();
    if(this.state.reset) return this.submitReset();

    if(this.state.loading || this.props.authenticated) return;
    let hasError = false;
    const { email, password } = this.refs;
    if(!email || !password) return;
    const eml = email.value, pwd = password.value;
    if(!eml) {
      email.parentNode.classList.add('error');
      hasError = true;
    }
    else email.parentNode.classList.remove('error');
    if(!pwd) {
      password.parentNode.classList.add('error');
      hasError = true;
    }
    else password.parentNode.classList.remove('error');

    if(hasError) return;

    this.setState({ loading: true });
    this.login(eml, pwd);
  }

  sendForgotEmail = e => {
    const { email } = this.refs;
    if(!email) return;
    const eml = email.value;
    if(!eml) email.parentNode.classList.add('error');
    else email.parentNode.classList.remove('error');
    if(eml) {
      this.setState({ loading: true });
      this.props.forgotPassword(eml).then(res => {
        if(!this._isMounted) return;
        const message = res.payload?.response?.errors?.[0];
        if(res.error) return this.setState({ 
          loading: false, 
          error: message || 'There was an error processing your request. Please check that you entered the right email and try again.' 
        });
        this.setState({ loading: false, emailSent: true });
        setTimeout(() => {
          if(!this._isMounted) return;
          if(this.refs.forgotMessage) {
            this.refs.forgotMessage.children[0].innerHTML = "Check your inbox for the password reset email we just sent.";
            this.refs.forgotMessage.classList.remove('fadeout');
            this.refs.forgotMessage.classList.add('confirm');
          }
        }, 750);
      })
    }
  }

  login = (email, pwd) => {
    this.props.login(email, pwd).then(res => {
      if(!this._isMounted) return;
      const newState = { loading: false, error: null };
      this.meta.user = res.payload.user;
      if(res.error) {
        const error = res.payload.response?.errors?.[0] || res.payload.message || res.payload.name;
        if(error === 'Enter a valid email address.') {
          if(this.refs.email) this.refs.email.parentNode.classList.add('error');
        }
        newState.error = error;
        if(this.refs.email?.value) this.refs.email.focus();
      }
      // rare occasion when a user's access to both products is seemingly disabled
      else if(!res.payload?.user?.access_search && !res.payload?.user?.access_data) {
        newState.error = 'Your account does not have access to any Grata products. Please contact us to remedy this situation.'
      }
      else {
        const { user } = res.payload;
        // if access to both products, give option for user to choose between the two
        if(user.access_data && user.access_search) {
          setTimeout(() => this.setState({ redirect: true }), 500);
        } 
        // otherwise, auto-redirect to the accessible product
        else {
          setTimeout(() => {
            if (user.access_data && !user.access_search) this.redirectToData();
            else if (!user.access_data && user.access_search) this.redirectToSearch();
          }, 1000)
        }
      }
      this.setState(newState);
    })
  }

  submitReset = e => {
    const { password, password2 } = this.refs;
    if(!password || !password) return;

    this.props.resetPassword({
      password1: password.value,
      password2: password2.value,
      token: this.state.resetInfo.token,
    }).then(res => {
      if(!this._isMounted) return;
      if(res.error) this.setState({ error: res.payload.response.errors.join(' ') });
      else this.login(this.state.resetInfo.email, password.value);
    })
  }

  keyPressListener = e => {
    if(!('metaKey' in e)) return;
    if(e.persist) e.persist();
    if(isEnter(e)) {
      e.target.blur();
      this.submit();
    }
  }

  render = () => {
    const { loading, forgot, reset, redirect, resetInfo } = this.state;
    const { authenticated } = this.props;
    const fadeout = forgot ? this.state.emailSent : (authenticated && !this.state.error);
    const buttonClasses = ['gsd-icon-button'];
    if(loading || fadeout) buttonClasses.push('active hover static');
    else {
      buttonClasses.push('text bg');
      if(this.state.error) buttonClasses.push('error');
    }

    return (
      <div className={`login-screen basic-screen ${forgot ? 'forgot' : ''} ${reset ? 'reset' : ''}`}>
        <Helmet title="Grata" />
        <div className="logo-bar">
          <Logo />
        </div>
        <div className={`redirect ${redirect ? 'show' : ''}`}>
          <div className="es product">
            <img alt="Grata Search" className="title-img" src={require('static/es.png')} />
            <img alt="Grata Search graphic" className="prod-img" id="es_prod_img" src={require('static/search.png')} />
            <div className="gsd-button large active" onClick={this.redirectToSearch}> Enter </div>
          </div>
          <div className="gd product">
            <img alt="Grata Data" className="title-img" src={require('static/gd.png')} />
            <img alt="Grata Data graphic" className="prod-img" src={require('static/data.png')} />
            <div className="gsd-button large active" onClick={this.redirectToData}> Enter </div>
          </div>
        </div>
        <div className="form-window">
          <div className="form-box">
            <div className={`form ${fadeout ? 'fadeout' : ''}`}>
              {
                forgot ? <div className="title-message">Enter the email address to send the password reset link to:</div>
                : reset ? <div className="title-message">Enter your new password for {resetInfo.name || resetInfo.email}</div>
                : null 
              }
              <div className="message">{ this.state.error }</div>
              {
                reset ? null :
                <div className="input">
                  <input name="email" type="email" ref="email" placeholder="user@example.com" />
                </div>
              }
              {
                forgot ? null : 
                <div className="input">
                  <input name="password" type="password" ref="password" placeholder={reset ? 'Password' : '••••••'} />
                </div>
              }
              {
                !reset ? null :
                <div className="input">
                  <input name="password2" type="password" ref="password2" placeholder="Confirm Password" />
                </div>
              }
            </div>
            <div className="button-box">
              <div className={buttonClasses.join(' ')} onClick={this.submit}>
                {
                  loading ? <div className="spinner" /> : 
                  <i className={`fa fa-${fadeout ? 'check' : forgot ? 'paper-plane' : 'arrow-right'}`} />
                }
              </div>
            </div>
            <div className={`forgot-message ${fadeout ? 'fadeout' : ''}`} ref="forgotMessage">
              <span onClick={e => {
                this.setState({ forgot: reset ? false : !forgot, loading: false, error: false });
                if(reset) this.props.history.replace('/');
              }}>
                { forgot || reset ? 'Back to log in' : 'Forgot password?' }
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(
  state => ({
    authenticated: state.auth.user?.token
  }),
  dispatch => bindActionCreators({
    login, logout, forgotPassword, loadResetToken, resetPassword
  }, dispatch)
)(Login)
