// select backend
const host = window.location.host;
let api_host = `https://${host}`,
    sentry_dsn = '',
    search_domain = '',
    data_domain = '',
    intercom = '',
    search_cookie_domain = '.grata.com',
    data_cookie_domain = '.gratadata.com';

if (host.includes('local')) {
    api_host = 'http://localhost:8090';
    search_domain = 'http://localhost:3000/a';
    data_domain = 'http://localhost:5000/a';
    intercom = 'igdqj55r';
    search_cookie_domain = '';
    data_cookie_domain = '';
} else if (host.includes('staging')) {
    sentry_dsn = 'https://7462b9e481eb4e04a099d4f4fc5fc273@sentry.io/1450391';
    data_domain = 'https://staging.gratadata.com/a';
    search_domain = 'https://search-staging.grata.com/a';
    data_cookie_domain = '';
    intercom = 'igdqj55r';
} else {
    intercom = 'urj5ygtx';
    sentry_dsn = 'https://9a34f54207c749878b7a33dcccedbfca@sentry.io/1467796';
    data_domain = 'https://data.gratadata.com/a';
    if (host.includes('gratadata.com')) {
        search_domain = 'https://search.gratadata.com/a';
        search_cookie_domain = '.gratadata.com';
    } else if (host.includes('grata.com')) {
        search_domain = 'https://search.grata.com/a';
    }
}

// DEV DEBUG
let debug = host.includes('local') ? 'local' : '';
// debug = 'pc';
// debug = 'staging';
// debug = 'prod';
if(debug === 'pc') { // virtual machine
    api_host = 'http://gsd.local:8090'; // replace gsd with your computer name
    search_domain = 'http://gsd.local:3000/a';
    data_domain = 'http://gsd.local:5000/a';
} else if(debug === 'staging') {
    api_host = 'https://login-staging.grata.com';
} else if(debug === 'prod') {
    api_host = 'https://login.grata.com';
}

export const SENTRY_DSN = sentry_dsn;
export const API_HOST = api_host;
export const SEARCH_DOMAIN = search_domain;
export const DATA_DOMAIN = data_domain;
export const SEARCH_COOKIE_DOMAIN = search_cookie_domain;
export const DATA_COOKIE_DOMAIN = data_cookie_domain;
export const DEV_STATE = debug;
export const INTERCOM = intercom;
