import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, verify } from 'reducers/auth';
import { parseQuery } from 'utils';
import { ReactComponent as Logo } from 'static/logo.svg';

require('./Verify.css');

class Verify extends Component {
  state = {
    success: false,
    loading: true,
    token: '',
    error: '',
  }

  componentWillMount = () => {
    if(this.props.authenticated) this.props.logout();
    const token = parseQuery(this.props.location.search).token;
    if(!token) return this.props.history.replace('/');
    this.setState({ token }, this.verify)
  }

  componentDidMount = () => {
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  verify = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.props.verify(this.state.token).then(res => {
        if(!this._isMounted) return;
        if(res.error) {
          const error = (res.payload?.response?.errors || []).join(' ');
          if(error.includes('token used')) return this.props.history.replace('/');
          return this.setState({ loading: false, error });
        }
        this.setState({ loading: false, success: true });
        setTimeout(() => this.props.history.replace('/'), 2000);
      })
    }, 1000)
  }

  render = () => {
    const { loading, success } = this.state;

    const buttonClasses = ['gsd-icon-button'];
    if(!loading && !success) buttonClasses.push('error');
    if(loading || success) buttonClasses.push('active hover static');
    else buttonClasses.push('text bg');

    return (
      <div className="basic-screen login-screen verify-screen">
        <div className="logo-bar">
          <Logo />
        </div>
        <div className="centered">
          <div className="button-box">
            <div className={buttonClasses.join(' ')} onClick={this.verify}>
              { loading ? <div className="spinner" /> : !success ? 'Activate' : <i className="fa fa-check" /> }
            </div>
          </div>
          <div className={`message ${loading || !success ? '' : 'hide'}`}>
            <span>{ loading ? 'Activating your account...' : success ? 'Activated' : (this.state.error || 'Activation failed. Please try again.')}</span>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(
  state => ({
    authenticated: state.auth.user?.token,
  }),
  dispatch => bindActionCreators({
    logout, verify
  }, dispatch)
)(Verify)
