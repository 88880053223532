import { RSAA } from 'redux-api-middleware';
import { API_HOST } from 'configs';

const injectMeta = (types, meta) => {
  return types.map(type => ({type, meta}))
}

const nooper = values => {
  if(values.type) return;
  if(!values.type && !values.types) {
    return values.types = ['NOOP', 'NOOP', 'NOOP']
  };
  for(let op in values.types) values.types[op] = values.types[op] ? values.types[op] : 'NOOP';
}

const withAuth = (headers, auth) => {
  let token = undefined;
  if (auth.user) {
    token = auth.user.token;
    if (token !== 'undefined' && token) {
      return {
        ...headers,
        Authorization: `Token ${token}`
      }
    }
  }
  return headers;
}

const patchEndpoint = (endpoint) => {
  if (endpoint.startsWith('http')) return endpoint;
  return `${API_HOST}/api${endpoint}`;
}


export const get = (values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  return (dispatch, getState) => {
    return dispatch({
      [RSAA]: Object.assign({}, values, {
        endpoint: patchEndpoint(values.endpoint),
        method: 'GET',
        headers: withAuth({}, getState().auth)
      })
    })
  }
}

export const del = (values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  if(values.body) values.body = JSON.stringify(values.body);
  return (dispatch, getState) => {
    return dispatch({
      [RSAA]: Object.assign({}, values, {
        endpoint: patchEndpoint(values.endpoint),
        method: 'DELETE',
        headers: withAuth({'Content-Type': 'application/json'}, getState().auth)
      })
    })
  }
}

export const post = (values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  if(values.body) values.body = JSON.stringify(values.body);
  return (dispatch, getState) => {
    return dispatch({
      [RSAA]: Object.assign({}, values, {
        endpoint: patchEndpoint(values.endpoint),
        method: 'POST',
        headers: withAuth({'Content-Type': 'application/json'}, getState().auth)
      })
    })
  }
}

export const post_file = (values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  return (dispatch, getState) => {
    return dispatch({
      [RSAA]: Object.assign({}, values, {
        endpoint: patchEndpoint(values.endpoint),
        method: 'POST',
        headers: withAuth({}, getState().auth)
      })
    })
  }
}

export const post_dispatch = (dispatch, getState, values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  if(values.body) values.body = JSON.stringify(values.body);
  return dispatch({
    [RSAA]: Object.assign({}, values, {
      endpoint: patchEndpoint(values.endpoint),
      method: 'POST',
      headers: withAuth({'Content-Type': 'application/json'}, getState().auth)
    })
  });
}

export const put = (values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  if(values.body) values.body = JSON.stringify(values.body);
  return (dispatch, getState) => {
    return dispatch({
      [RSAA]: Object.assign({}, values, {
        endpoint: patchEndpoint(values.endpoint),
        method: 'PUT',
        headers: withAuth({'Content-Type': 'application/json'}, getState().auth)
      })
    })
  }
}

export const patch = (values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  if(values.body) values.body = JSON.stringify(values.body);
  return (dispatch, getState) => {
    return dispatch({
      [RSAA]: Object.assign({}, values, {
        endpoint: patchEndpoint(values.endpoint),
        method: 'PATCH',
        headers: withAuth({'Content-Type': 'application/json'}, getState().auth)
      })
    })
  }
}

export const put_dispatch = (dispatch, getState, values, meta) => {
  nooper(values);
  if(meta) values.types = injectMeta(values.types, meta)
  if(values.body) values.body = JSON.stringify(values.body);
  return dispatch({
    [RSAA]: Object.assign({}, values, {
      endpoint: patchEndpoint(values.endpoint),
      method: 'PUT',
      headers: withAuth({'Content-Type': 'application/json'}, getState().auth)
    })
  });
}

export const delay = (values, meta) => {
  nooper(values);
  if(meta) values.meta = meta;
  return (dispatch) => {
    dispatch(values);
    return Promise.resolve();
  }
}

export const encode = filters => {
  // Construct query string from filters.
  const queryString = Object.keys(filters).map(key => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
  }).join('&');
  return queryString;
}

export const get_url_query = (obj) => {
  let addedQuery = ''
  if(obj) Object.keys(obj).forEach(k => addedQuery += `&${k}=${obj[k] || ''}`)
  return addedQuery
} 
