import React from 'react';
import { Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import { DEV_STATE, INTERCOM } from 'configs';
import Intercom, { IntercomAPI } from 'react-intercom';

const PublicRoute = ({ component: ViewComponent, ...rest }) => {

  setTimeout(() => {
    if(window.Intercom) {
      IntercomAPI('boot', { app_id: INTERCOM })
    }
  }, 500)

  return [
    <Route key="app" {...rest} render={props => <ViewComponent {...props} />} />,
    DEV_STATE ? <div key="dev" className="dev-tag" title={`Server set to: ${DEV_STATE}`} data-server={DEV_STATE} /> : null,
    <Intercom key="intercom" appID={INTERCOM} />
  ];
  
}

export default withRouter(PublicRoute)


