export const parseQuery = str => {
  var query = {};
  var pairs = (str[0] === '?' ? str.substr(1) : str).split('&');
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const isEnter = e => (e.key || e.keyCode || e.code) === 'Enter' || e.which === 13;
export const isEsc = e => (e.key || e.keyCode || e.code) === 'Escape' || e.which === 27;